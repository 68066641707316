import {convertEnum} from "../enumUtil"
/**
 * 性别
 */
export const EStoreStatus = {
  /** 启用 */
  Enable: 1,

  /** 禁用 */
  Disable: 2,

};
// console.log(eSex,'ESexESexESexESex');
export const [MStoreStatus, OStoreStatus] = convertEnum([
  [EStoreStatus.Enable, "启用"],
  [EStoreStatus.Disable, "禁用"],
]);
