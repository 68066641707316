<template>
  <div>
    <el-drawer :visible.sync="drawer" size="1100px" :direction="direction" :before-close="handleClose"
      :title="storeInfo.name" :show-close="true">
      <div style="padding-top: '50px'">
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="基本信息" name="basic">
            <div class="section">
              <!-- <div class="title">用户信息</div> -->
              <ul class="list">
                <li class="item item100">
                  <div class="item-title">logo：</div>
                  <el-image :src="storeInfo.logo" :preview-src-list="[storeInfo.logo]"
                    style="width:140px;height:140px;margin-right:12px;" />
                </li>
                <li class="item item100">
                  <div class="item-title">资质：</div>
                  <div v-if="storeInfo.qualifications">
                    <el-image v-for="(pic, idx) in storeInfo.qualifications.split(',')" :preview-src-list="[pic]"
                      :key="idx" :src="pic" style="width:140px;height:140px;margin-right:12px;" />
                  </div>
                </li>
              </ul>
              <!-- <li class="item item100">
                <div class="item-title">商品简介：</div>
                <div class="value">{{ storeInfo.store_info }}</div>
              </li> -->
              <ul class="list">
                <li class="item">
                  <div class="item-title">联系人：</div>
                  <div class="value">{{ storeInfo.contact }}</div>
                </li>
                <li class="item">
                  <div class="item-title">联系人手机号：</div>
                  <div class="value">
                    <div class="value">{{ storeInfo.mobile }}</div>
                  </div>
                </li>
                <li class="item">
                  <div class="item-title">客户单位名称：</div>
                  <div class="value">{{ storeInfo.corporateName }}</div>
                </li>
                <li class="item">
                  <div class="item-title">合同备案号：</div>
                  <div class="value">{{ storeInfo.filingNumber }}</div>
                </li>
              </ul>
              <!-- <ul v-if="storeInfo.video_link" class="list">
                <li class="item item100">
                  <div class="item-title">主图视频：</div>
                  <video style="width:300px;height: 150px;border-radius: 10px;" :src="storeInfo.video_link"
                    controls="controls">
                    您的浏览器不支持 video 标签。
                  </video>
                </li>
              </ul> -->
            </div>
          </el-tab-pane>
          <el-tab-pane label="账号信息" name="account">
            <div class="section">
              <ul class="list">
                <li class="item">
                  <div class="item-title">商户账号：</div>
                  <div class="value">{{ storeInfo.username }}</div>
                </li>
                <li class="item">
                  <div class="item-title">创建时间：</div>
                  <div class="value">
                    <div class="value">{{ storeInfo.createTime }}</div>
                  </div>
                </li>
                <li class="item">
                  <div class="item-title">生效时间：</div>
                  <div class="value">{{ storeInfo.startTime }}</div>
                </li>
                <li class="item">
                  <div class="item-title">关闭时间：</div>
                  <div class="value">{{ storeInfo.endTime }}</div>
                </li>
                <li class="item">
                  <div class="item-title">开启状态：</div>
                  <div class="value">{{ storeInfo.state == 1 ? '启动' : '禁用' }}</div>
                </li>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane label="详情信息" name="detail">
            <div class="section">
              <div class="contentPic" v-html="storeInfo.introduce"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>



export default {
  components: {},
  props: {
    storeInfo: {
      type: Object,
      default: {},
    },
    drawer: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      direction: 'rtl',
      activeName: 'basic',
    };
  },

  methods: {
    handleClose() {
      this.activeName = 'basic';
      this.$emit('closeDrawer');
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  padding: 20px 35px;

  .full {
    display: flex;
    align-items: center;

    .order_icon {
      width: 60px;
      height: 60px;
    }

    .iconfont {
      color: var(--prev-color-primary);

      &.sale-after {
        color: #90add5;
      }
    }

    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;

      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }

      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }

  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;

    .item {
      flex: none;
      width: 20%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);

      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}

.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;

  &:before {
    display: none;
  }
}

.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}

.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item {
    width: 500px;
    // flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;

    // &:nth-child(3n + 1) {
    //   padding-right: 20px;
    // }

    // &:nth-child(3n + 2) {
    //   padding-right: 10px;
    //   padding-left: 10px;
    // }

    // &:nth-child(3n + 3) {
    //   padding-left: 20px;
    // }

    .item-title {
      width: 100px;
      text-align: right;
    }
  }

  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }

  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;

    /deep/img {
      max-width: 100%;
    }
  }

  .value {

    // flex: 1;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}

.tab {
  display: flex;
  align-items: center;

  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}

/deep/.el-drawer__body {
  overflow: auto;
}

li {
  display: block;
}

/deep/.ones th {
  background: #F0F5FF;
}
</style>
