import { get, put,Delete,post } from '../axios'

// 平台端-门店列表
export const storeList = params => get(`/user/web/store/list`,params);

// 平台端-门店管理-新增
export const storeAdd = params => post(`/user/web/store/add`,params);

// 平台端-门店管理-是否启用-修改
export const storeUpdate = params => put(`/user/web/store/update`,params);

// // 应用管理-删除
// export const projectDelete = params => Delete('/user/web/lanxin/project/delete/' + params.id, '');

//平台端-门店管理-重置密码
export const storeUpdatePassword = params => put(`/user/web/store/updatePassword/${params}`);

// 平台端-门店管理-详情
export const storedetail = params => get('/user/web/store/detail/' + params, '');

// 平台端-门店管理-是否启用-修改
export const stateUpdate = params => put(`/user/web/store/state/update`,params);

// 平台端-设置店铺积分
export const updateIntegral = params => put(`/user/web/store/updateIntegral`,params);

// 平台端-查看店铺积分
export const storeIntegral = params => get(`/user/web/store/integral/${params}`);
//门店列表-支付状态
export const payStateUpdate = params => put(`/user/web/store/pay/state/update`,params);
//门店-再次开启
export const storeReopen = params => put(`/user/web/store/reopen`,params);
//门店-积分转移
export const transferIntegral = params => put(`/user/web/clientUser/transferIntegral`,params);