<template>
  <div class="divBox">
    <!-- 搜索栏 -->
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        size="small"
        label-width="85px"
        :inline="true"
      >
        <el-form-item label="联系人：" prop="contact">
          <el-input
            v-model="tableFrom.contact"
            @keyup.enter.native="getList(1)"
            placeholder="请输入联系人"
            class="selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="门店名称：" prop="name">
          <el-input
            v-model="tableFrom.name"
            @keyup.enter.native="getList(1)"
            placeholder="请输入门店名称"
            class="selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="店铺状态：" prop="state">
          <el-select
            v-model="tableFrom.state"
            clearable
            placeholder="请选择"
            class="selWidth"
            @change="getList(1)"
          >
            <el-option
              v-for="(i, index) in OStoreStatus"
              :key="index"
              :label="i.label"
              :value="i.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="getList(1)"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格区域 -->
    <el-card class="mt14">
      <div class="mb20 marginBottom20">
        <el-button size="small" type="primary" class="mt5" @click="onAdd"
          >添加门店</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
        <el-table-column label="序号" type="index" width="100">
        </el-table-column>
        <!-- <el-table-column prop="id" label="ID" min-width="180" /> -->
        <el-table-column prop="name" label="门店名称" min-width="150" />
        <el-table-column prop="logo" label="logo" min-width="100">
          <template slot-scope="scope">
             <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.logo"
              :preview-src-list="[scope.row.logo]"
            />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="qualifications" label="资质：" min-width="100">
          <template slot-scope="scope">
            <div>
              <el-image v-for="(item, index) in scope.row.qualifications.split(',')" :key="index"
                style="width: 36px; height: 36px" :src="item" :preview-src-list="[item]" />
            </div>
             
          </template>
        </el-table-column> -->
        <el-table-column prop="contact" label="联系人" min-width="150" />
        <el-table-column
          prop="corporateName"
          label="单位名称"
          min-width="150"
        />
        <el-table-column
          prop="filingNumber"
          label="合同备案号"
          min-width="150"
        />
        <el-table-column prop="state" label="状态" min-width="100">
          <template slot-scope="scope">
            <el-switch
              @change="stateChange(scope.row)"
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="290" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="integralSetAgain(scope.row)"
                >再次开启</el-button
              >
              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="integralSetTransfer(scope.row)"
                >积分转移</el-button
              >
              <el-button
                icon="el-icon-view"
                type="text"
                size="small"
                @click="onDetails(scope.row.id)"
                >详情</el-button
              >
              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="onEdit(scope.row.id)"
                >编辑</el-button
              >
            </div>
            <div>
              <el-button
                icon="el-icon-refresh"
                type="text"
                size="small"
                @click="updatePassword(scope.row.id)"
                >重置密码</el-button
              >

              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="integralSet(scope.row)"
                >设置积分</el-button
              >

              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="upPay(scope.row)"
                >{{
                  scope.row.closePay == 0 ? "关闭在线支付" : "开启在线支付"
                }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <!--门店新增-->
    <el-drawer
      :title="storeID ? '编辑门店' : '添加门店'"
      :size="800"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div v-loading="loading" class="form_content">
        <el-form
          ref="form"
          size="small"
          :rules="ruleValidate"
          :model="form"
          label-width="130px"
          @submit.native.prevent
        >
          <el-tabs v-loading="false" type="border-card" v-model="activeName">
            <el-tab-pane label="基本信息" name="detail">
              <div class="section">
                <el-form-item label="门店名称：" prop="name">
                  <el-input
                    size="small"
                    v-model="form.name"
                    placeholder="请输入商户名称"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="联系人：" prop="contact">
                  <el-input
                    size="small"
                    v-model="form.contact"
                    placeholder="请输入"
                    maxlength="20"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="扫码进店：" prop="contact">
                  <el-switch
                    v-model="form.enterType"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#cccccc"
                  >
                  </el-switch>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      开启：扫码登录即成为白名单用户<br />关闭：仅支持后台导入白名单
                    </div>
                    <i
                      style="margin-left: 5px; color: #999999"
                      class="el-icon-question"
                    ></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="联系人手机：" prop="mobile">
                  <el-input
                    size="small"
                    v-model="form.mobile"
                    placeholder="请输入"
                    maxlength="11"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="客户单位名称：" prop="corporateName">
                  <el-input
                    size="small"
                    v-model="form.corporateName"
                    maxlength="10"
                    placeholder="请输入客户单位名称"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="合同备案号：" prop="filingNumber">
                  <el-input
                    size="small"
                    v-model="form.filingNumber"
                    placeholder="请输入填合同备案号"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="logo：" prop="logo">
                  <FormImgUpload
                    :url="form.logo"
                    @upload="upload"
                    :limit="1"
                    :maxSize="2"
                    accept="image/jpeg,image/png,image/jpg"
                    :multiple="true"
                  />
                  <div class="tipImg">尺寸推荐:344x516，格式:jpeg,png,jpg</div>
                </el-form-item>
                <el-form-item label="背景图片：" prop="background">
                  <FormImgUpload
                    :url="form.background"
                    @upload="backgroundUpload"
                    :limit="1"
                    :maxSize="2"
                    accept="image/jpeg,image/png,image/jpg"
                    :multiple="true"
                  />
                  <div class="tipImg">尺寸推荐:710*532，格式:jpeg,png,jpg</div>
                </el-form-item>
                <!-- <el-form-item label="资质：" prop="qualifications">
                  <FormImgUpload :url="form.qualifications" @upload="uploadQualifications" :limit="3" :maxSize="2"
                    accept="image/jpeg,image/png,image/jpg" :multiple="true" />
                </el-form-item> -->
              </div>
            </el-tab-pane>
            <el-tab-pane label="账号信息" name="account">
              <div class="section">
                <el-form-item label="商户账号" prop="username">
                  <el-input
                    size="small"
                    :disabled="Boolean(storeID)"
                    v-model="form.username"
                    placeholder="请输入"
                    maxlength="20"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item v-if="!storeID" label="登录密码" prop="password">
                  <el-input
                    size="small"
                    v-model="form.password"
                    show-password
                    placeholder="请输入"
                    maxlength="20"
                    class="selWidth"
                  />
                </el-form-item>
                <el-form-item label="有效时间" prop="dataTime">
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.dataTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="状态：" prop="state">
                  <el-switch
                    v-model="form.state"
                    :active-value="EStoreStatus.Enable"
                    :inactive-value="EStoreStatus.Disable"
                    :active-text="MStoreStatus[EStoreStatus.Enable]"
                    :inactive-text="MStoreStatus[EStoreStatus.Disable]"
                  />
                </el-form-item>
              </div>
            </el-tab-pane>
            <el-tab-pane label="详情信息" name="info">
              <div class="section">
                <el-form-item label="图文介绍：" prop="introduce">
                  <FormEditor
                    :details="form.introduce"
                    @editoChange="editoChange"
                  />
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button
          v-if="activeName === 'detail'"
          type="primary"
          @click="activeName = 'account'"
          >下一步</el-button
        >
        <el-button
          v-if="activeName === 'account'"
          type="primary"
          @click="activeName = 'info'"
          >下一步</el-button
        >
        <el-button
          v-if="activeName === 'info'"
          type="primary"
          :loading="showLoadingForm"
          @click="submitInfo(form)"
          >确认</el-button
        >
      </div>
    </el-drawer>
    <!--积分 -->
    <el-drawer
      title="设置积分"
      :size="800"
      :visible.sync="integralShow"
      direction="rtl"
      :before-close="
        () => {
          integralShow = false;
        }
      "
    >
      <el-form
        ref="integraForm"
        size="small"
        :rules="integralrule"
        :model="integraForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item label="总积分" prop="integral">
          <el-input
            oninput="value=value.replace(/[^\d]/g,'')"
            size="small"
            v-model="integraForm.integral"
            placeholder="请输入"
            maxlength="9"
            class="selWidth"
          />
          <div class="tipIntegral">
            注意：为0时不限制门店用户消费，大于0时超过输入数字限制门店用户消费
          </div>
        </el-form-item>
        <el-form-item label="积分比例" prop="ratio">
          <el-input
            οninput="value=value.replace(/[^0-9.]/g,'')"
            :disabled="integraDisabled"
            size="small"
            maxlength="5"
            v-model="integraForm.ratio"
            placeholder="请输入"
            class="selWidth"
          >
            <template slot="prepend">1元：</template>
            <template slot="append">积分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="积分规则" prop="rule">
          <el-input
            type="textarea"
            show-word-limit
            size="medium"
            :autosize="{ minRows: 5, maxRows: 10 }"
            v-model="integraForm.rule"
            placeholder="请输入"
            maxlength="200"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item label="开启时间" prop="time" v-if="integralSetType == 2">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="integraForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button
          @click="
            () => {
              integralShow = false;
            }
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="showLoadingForm"
          @click="integralEdit(integraForm)"
          >确认</el-button
        >
      </div>
    </el-drawer>
    <!-- 详情 -->
    <StoreDetails
      @closeDrawer="detailShow = false"
      :storeInfo="storeInfo"
      :drawer="detailShow"
    ></StoreDetails>

    <!-- 积分转移 -->
    <el-dialog
      title="提示"
      :visible.sync="integralDialogVisiblel"
      width="700px"
    >
      <p style="margin-bottom: 40px">
        设置积分转移，会将该门店的的用户积分转移到宝鸟主门店中，设置用户积分的转换比例，请谨慎操作
      </p>
      <el-form>
        <el-form-item label="转换比例:" label-width="84px">
          <el-input
            placeholder="请输入内容"
            v-model="transformation"
            style="width: 500px"
            type="number"
          >
            <template slot="prepend">1门店积分等于</template>
            <template slot="append">宝鸟积分</template>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="integralDialogVisiblel = false">取 消</el-button>
        <el-button
          :loading="transformationLoading"
          type="primary"
          @click="transformationSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  storeList,
  storeAdd,
  storeUpdatePassword,
  storedetail,
  stateUpdate,
  updateIntegral,
  storeReopen,
  storeIntegral,
  storeUpdate,
  payStateUpdate,
  transferIntegral,
} from "@/api/store/list";
import {
  MStoreStatus,
  OStoreStatus,
  EStoreStatus,
} from "../../../enums/beCurrent/EBeCurrent";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import FormEditor from "@/components/formEditor";
import { mobilePhoneRule } from "@/utils/formRules";
import StoreDetails from "./comments/storeDetails.vue";
import { dataTool } from "echarts";

export default {
  name: "MerchantList",
  components: { FormImgUpload, FormEditor, StoreDetails },
  data() {
    return {
      integralDialogVisiblel: false, //积分转移弹框
      transformation: "", //转换比例
      transformationLoading: false,
      listLoading: true,
      detailShow: false,
      integralShow: false,
      storeInfo: {},
      activeName: "detail",
      OStoreStatus: OStoreStatus, //店铺状态
      EStoreStatus: EStoreStatus,
      MStoreStatus: MStoreStatus,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      storeID: "",
      drawer: false,
      form: {
        logo: [],
        // qualifications: [],
        background: [],
        introduce: "",
        state: 2,
        name: "",
        username: "",
        password: "",
        contact: "",
        filingNumber: "",
        mobile: "",
        corporateName: "",
        dataTime: "",
        enterType: 0,
      },
      loading: false, //抽屉动画
      integralrule: {
        integral: [{ required: true, message: "请输入", trigger: "blur" }],
        ratio: [
          { required: true, message: "数值不能为空" },
          { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
          // { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' },
        ],

        rule: [{ required: true, message: "请输入", trigger: "blur" }],
        time: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      integraForm: {
        integral: 0,
        ratio: null,
        rule: "",
        time: [],
      },
      ruleValidate: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        logo: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        background: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        mobile: [
          { required: true, message: "请输入", trigger: "blur" },
          ...mobilePhoneRule,
        ],
        state: [{ required: true, message: "请选择", trigger: "blur" }],
        // qualifications: [
        //   {
        //     required: true,
        //     message: "请上传",
        //     trigger: "blur",
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (value.length === 0) {
        //         callback(new Error("请上传"));
        //       } else {
        //         callback();
        //       }
        //     },
        //   },
        // ],
        corporateName: [{ required: true, message: "请输入", trigger: "blur" }],
        contact: [{ required: true, message: "请输入", trigger: "blur" }],
        filingNumber: [{ required: true, message: "请输入", trigger: "blur" }],
        username: [{ required: true, message: "请输入", trigger: "blur" }],
        password: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
            message: "数字+大字母+小字母，需要8位起",
            trigger: "blur",
          },
        ],
        // dataTime: [{ required: true, message: "请选择", trigger: "blur" }],
        introduce: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
          {
            validator(_, value) {
              if (value) {
                if (value === "<p><br></p>") {
                  return Promise.reject(new Error("请输入内容"));
                }
                if (value.length > 65535) {
                  return Promise.reject(new Error("内容超出最大长度限制"));
                }
              }
              return Promise.resolve(true);
            },
          },
        ],
      }, //表单校验
      showLoadingForm: false,
      integraObj: {}, //当前处理的积分门店
      integraDisabled: false,
      integralSetType: 1, //1设置积分 2再次开启
    };
  },
  mounted() {
    this.getList("");
  },
  methods: {
    //修改在线支付状态
    upPay(row) {
      this.$confirm(
        row.closePay == 0 ? "是否关闭在线支付?" : "是否开启在线支付?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        payStateUpdate({
          id: row.id,
          closePay: row.closePay == 0 ? 1 : 0,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 切换门店状态
    async stateChange(item) {
      stateUpdate({
        id: item.id,
        state: item.state,
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList("");
      });
    },
    //再次开启
    async integralSetAgain(item) {
      this.integralSetType = 2;
      const { code, data } = await storeIntegral(item.id);
      if (code == 200) {
        this.integraObj = item;
        this.integralShow = true;
        if (data) {
          this.integraForm = data;
          this.integraDisabled = false;
        } else {
          this.$nextTick(() => {
            this.$refs["integraForm"].resetFields();
          });
        }
      }
    },
    //积分转移
    async transformationSubmit() {
      if (!this.transformation) {
        this.$message.error("请输入转换比例");
        return;
      }
      try {
        this.transformationLoading = true;
        const { code, data } = await transferIntegral({
          id: this.integraObj.id,
          ratio: this.transformation,
        });
        this.transformationLoading = false;
        if (code == 200) {
          this.integraObj = item;
          this.integralShow = true;
          if (data) {
            this.integraForm = data;
            this.integraDisabled = false;
          } else {
            this.$nextTick(() => {
              this.$refs["integraForm"].resetFields();
            });
          }
        }
      } catch (error) {
        this.transformationLoading = false;
      }
    },
    //积分转移
    integralSetTransfer(item) {
      this.transformation = "";
      this.integraObj = item;
      this.integralDialogVisiblel = true;
    },
    // 设置积分
    async integralSet(item) {
      this.integralSetType = 1;
      const { code, data } = await storeIntegral(item.id);
      if (code == 200) {
        this.integraObj = item;
        this.integralShow = true;
        if (data) {
          this.integraForm = data;
          this.integraDisabled = true;
        } else {
          this.integraDisabled = false;
          this.$nextTick(() => {
            this.$refs["integraForm"].resetFields();
          });
        }
      }
      // const { code, data } = await updateIntegral({
      //   id,
      //   integral: 10000,
      //   ratio: 2,
      //   rule: '我是规程'
      // })
    },
    integralEdit(integraForm) {
      this.$refs.integraForm.validate(async (valid, done) => {
        if (valid) {
          if (this.integralSetType == 1) {
            this.showLoadingForm = true;
            const { code, data } = await updateIntegral({
              id: this.integraObj.id,
              integral: integraForm.integral,
              ratio: integraForm.ratio,
              rule: integraForm.rule,
            });
            if (code == 200) {
              this.integralShow = false;
              this.$message.success("操作成功");
            }
            this.showLoadingForm = false;
          } else {
            this.showLoadingForm = true;
            const { code, data } = await storeReopen({
              id: this.integraObj.id,
              integral: integraForm.integral,
              ratio: integraForm.ratio,
              rule: integraForm.rule,
              startTime: integraForm.time[0],
              endTime: integraForm.time[1],
            });
            if (code == 200) {
              this.integralShow = false;
              this.$message.success("操作成功");
            }
            this.showLoadingForm = false;
          }
        }
      });
    },
    // 重置密码
    async updatePassword(id) {
      const { code, data } = await storeUpdatePassword(id);
      if (code == 200) {
        this.$message.success("操作成功");
      }
    },
    /**重置 */
    searchReset() {
      this.tableFrom = {
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },

    // 列表
    getList(num) {
      this.listLoading = true;
      this.tableFrom.pageNum = num ? num : this.tableFrom.pageNum;
      storeList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },

    // 添加
    onAdd() {
      this.storeID = "";
      this.activeName = "detail";
      this.form.password = "";
      this.form = {
        logo: [], // qualifications: [],
        background: [],
        introduce: "",
        state: 2,
        name: "",
        username: "",
        password: "",
        contact: "",
        filingNumber: "",
        mobile: "",
        corporateName: "",
        dataTime: "",
        enterType: 0,
      };
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      // this.$refs["form"].resetFields();
      this.drawer = true;
    },
    async onDetails(id) {
      const { code, data } = await storedetail(id);
      if (code == 200) {
        this.storeInfo = data;
        this.detailShow = true;
      }
    },
    // 编辑
    async onEdit(id) {
      this.storeID = id;
      this.activeName = "detail";
      const { code, data } = await storedetail(id);
      if (code == 200) {
        this.form = {
          ...data,
          logo: data.logo.split(","),
          // qualifications: data.qualifications.split(','),
          background: data.background.split(","),
          dataTime: [data.startTime, data.endTime],
        };
        console.log(data);
      }
      this.drawer = true;
    },

    changeDrawer(v) {
      this.drawer = v;
    },
    closeDrawer() {
      this.drawer = false;
    },

    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.logo = [...e];
      } else {
        this.form.logo = [...this.form.logo, e];
      }
    },
    backgroundUpload(e) {
      if (Array.isArray(e)) {
        this.form.background = [...e];
      } else {
        this.form.background = [...this.form.background, e];
      }
    },
    //资质
    uploadQualifications(e) {
      if (Array.isArray(e)) {
        this.form.qualifications = [...e];
      } else {
        this.form.qualifications = [...this.form.qualifications, e];
      }
    },
    //抽屉关闭
    handleClose() {
      this.drawer = false;
    },

    //富文本框变化替换值
    editoChange(e) {
      this.form.introduce = e;
    },
    //表单提交
    submitInfo(form) {
      this.$refs.form.validate((valid, done) => {
        if (valid) {
          this.showLoadingForm = true;
          // console.log(form, "formformform");
          // return
          let parame = {
            ...form,
            logo: form.logo.join(","),
            //qualifications: form.qualifications.join(','),
            background: form.background.join(","),
          };
          console.log(form.dataTime, "form.dataTime.length");
          if (form.dataTime) {
            if (form.dataTime.length) {
              parame.startTime = form.dataTime[0] ? form.dataTime[0] : null;
              parame.endTime = form.dataTime[1] ? form.dataTime[1] : null;
              console.log("12312", parame);
            } else {
              parame.startTime = null;
              parame.endTime = null;
            }
          }
          if (this.storeID) {
            console.log("编辑");
            storeUpdate({ ...parame, id: this.storeID })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.getList();
                  this.dialogVisible = false;
                  // setTimeout(() => {
                  //   this.resetForm();
                  // }, 500);
                  // this.onLoad();
                  this.showLoadingForm = false;
                  this.drawer = false;
                  // this.getList()
                } else {
                  this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  this.drawer = false;
                }
              })
              .catch(() => {
                this.showLoadingForm = false;
                this.drawer = false;
              });
          } else {
            storeAdd(parame)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  this.showLoadingForm = false;
                  this.drawer = false;
                  this.getList();
                } else {
                  this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  this.drawer = false;
                }
              })
              .catch(() => {
                this.showLoadingForm = false;
                // this.drawer = false;
              });
          }
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form_content {
  margin: 0 30px;
  margin-bottom: 70px;
}

.selWidth {
  width: 300px;
}

.demo-drawer__footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #ddd;
  padding: 10px 30px;
  display: flex;
  justify-content: right;
  width: 100%;
  background: #ffffff;
}

.content_top_right_user {
  width: 40px;
  height: 40px;
  margin-left: -20px;
  border-radius: 50%;
}

.content_top_right_user:first-of-type {
  margin-left: 0px;
}

.tipIntegral {
  color: #999999;
  margin-top: 5px;
}

// .el-button+.el-button{
//     background-color: #409EFF !important;
//   }
</style>
