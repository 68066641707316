/**
 * 将数组转换成对象
 *
 * @export
 * @param {string[][]} data
 * @return {*}
 */
export function convertEnumMap(data) {
    return data.reduce((obj, [key, value = '']) => {
      obj[key] = value
      return obj
    })
  }
  
  /**
   * 将数组转换成下拉框数组
   */
  export function convertEnumArray(data) {
    return data.map(([value, label]) => ({ label, value }))
  }
  
  /**
   * 将枚举同时转化为对象和数组
   */
  export function convertEnum(data) {
    return [convertEnumMap(data), convertEnumArray(data)]
  }
  